/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-16 09:47:53
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-16 16:32:19
 */
export const columns = [
{
    title: '分类名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'left',
    ellipsis:true,
},
{
    title: '分类图标',
    dataIndex: 'imgUrl',
    key: 'imgUrl',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'imgUrl'}
},
{
    title: '是否使用折扣&政策',
    dataIndex: 'usedRebatePolicy',
    key: 'usedRebatePolicy',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'usedRebatePolicy'}
},
{
    title: '是否使用积分',
    dataIndex: 'usedIntegral',
    key: 'usedIntegral',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'usedIntegral'}
},
{
    title: '是否返还积分',
    dataIndex: 'returnIntegral',
    key: 'returnIntegral',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'returnIntegral'}
},
{
    title: '返还积分比例',
    dataIndex: 'returnIntegralNum',
    key: 'returnIntegralNum',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '操作时间',
    dataIndex: 'modifyTime',
    key: 'modifyTime',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]
